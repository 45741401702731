import Link from "next/link";
import { FC } from "react";
import styled from "styled-components";

export type LinkOrButtonType = {
  href?: string;
  onClick?: (...args: any[]) => void;
  className?: string;
  download?: boolean;
};

export const LinkOrButton: FC<LinkOrButtonType | any> = ({
  className,
  onClick,
  href,
  children,
  isLoading,
  empty,
  ...props
}) => {
  if (href) {
    return (
      <Link href={href}>
        <a className={className} onClick={onClick} {...props}>
          {children}
        </a>
      </Link>
    );
  } else if (onClick) {
    return (
      <_Button onClick={onClick} className={className} {...props}>
        {children}
      </_Button>
    );
  }
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

const _Button = styled.button`
  font-family: inherit;
`;
