import { createContext } from "react";
import { RippleType } from "../../components/atoms/Ripple/RippleType";

type ContextProps = {
  ripples: Partial<RippleType>[];
  setRipples: (...args: any[]) => any;
  removeRippleById: (...args: any[]) => any;
};

export const RippleContext = createContext<ContextProps>({} as ContextProps);

export default RippleContext;
