import styled from "styled-components";
import { Svg } from "../../atoms/Svg/Svg";

export const _Svg = styled(Svg)`
  .injected-svg {
    width: 178px;
  }
`;

export const _MenuItems = styled.div`
  list-style: none;
  margin: 0;
  padding: 0;

  &:last-child {
    margin-top: auto;
  }
`;

export const _MenuItemsBottom = styled.div`
  list-style: none;
  margin: 0;
  padding: 0 0 env(safe-area-inset-bottom) 0;

  &:last-child {
    margin-top: auto;
  }
`;
