import React, { PropsWithChildren, ReactNode } from "react";
import PwaBanner from "../PwaBanner";
import Link from "../../atoms/Link";
import TopBar from "../TopBar";
import Dock from "../Dock/Dock.connect";
import { LayoutVariant } from "./Layout.types";
import {
  _AuthFooter,
  _AuthHeader,
  _Bottom,
  _Content,
  _Outer,
  _Scroll,
  _Top,
} from "./_Layout";
import Backdrop, { BackdropVariant } from "../../atoms/Backdrop";
import styled from "styled-components";
import { useLabel } from "../../../hooks/useLabel";
import { useRouter } from "next/router";
import CookieConsent from "../CookieConsent/CookieConsent";
import { useCookiesConsent } from "../../../hooks/useCookiesConsent";
import { GTM } from "../../../components/atoms/GTM";

interface LayoutProps {
  title?: ReactNode;
  footerContent?: ReactNode;
  variant?: LayoutVariant;
}

export default function Layout({
  children,
  footerContent,
  title,
  variant = LayoutVariant.DASHBOARD,
}: PropsWithChildren<LayoutProps>) {
  const { label } = useLabel();
  const router = useRouter();

  const { isAccepted, isNotSet } = useCookiesConsent();

  const isCookiePage = router?.pathname === "/cookies";

  const showCookieConsent =
    variant === LayoutVariant.DASHBOARD && isNotSet && !isCookiePage;

  return (
    <>
      <Backdrop
        variant={
          variant === LayoutVariant.AUTH
            ? BackdropVariant.AUTH
            : BackdropVariant.DEFAULT
        }
      />
      <_Outer>
        {isAccepted && <GTM />}
        {showCookieConsent && <CookieConsent />}
        <_Content>
          <_Scroll children={children} />
        </_Content>
        <_Top>
          {variant === LayoutVariant.AUTH ? (
            <_Container>
              <_AuthHeader>
                <Link href="/">
                  <_Logo
                    src={label.assets.logoFull.src}
                    width={label.assets.logoFull.width}
                    height={label.assets.logoFull.height}
                  />
                </Link>
              </_AuthHeader>
            </_Container>
          ) : (
            <TopBar title={title} isHomepage={router?.pathname === "/"} />
          )}
        </_Top>
        <_Bottom>
          {variant === LayoutVariant.DASHBOARD ? (
            <Dock />
          ) : (
            <_AuthFooter children={footerContent} />
          )}
        </_Bottom>
      </_Outer>
      <PwaBanner />
    </>
  );
}

const _Logo = styled.img`
  width: auto;
  max-width: 170px;
  height: auto;
  max-height: 38px;
`;

const _Container = styled.div`
  max-width: 576px;
  margin: 0 auto;
`;
