import React from "react";
import { useLabel } from "../../../hooks/useLabel";
import { BackdropVariant } from "./Backdrop.types";
import * as styled from "./_Backdrop";

type BackdropProps = React.PropsWithChildren<{ variant: BackdropVariant }>;

export function Backdrop({ variant }: BackdropProps) {
  const { label } = useLabel();

  return (
    <styled._Backdrop variant={variant}>
      <styled._BgShapesTop
        style={{ backgroundImage: `url(${label.assets.backdrop?.top.src})` }}
      />
      <styled._ArtworkLeft
        src={label.assets.backdrop?.left.src}
        width="123px"
        height="296px"
      />
      <styled._ArtworkRight
        src={label.assets.backdrop?.right.src}
        width="102px"
        height="285px"
      />
    </styled._Backdrop>
  );
}
