import styled from "styled-components";

export const _LanguageDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  color: #979797;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const _LanguageDropdown = styled.select`
  padding: 10px 10px 10px 5px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 0.875rem;
  font-family: "Isidora Sans Webfont", sans-serif;
  color: #979797;
`;
