import styled from "styled-components";

interface _CanvasProps {
  isVisible?: boolean;
}

export const _Canvas = styled.div<_CanvasProps>`
  position: fixed;
  top: 0;
  right: 0;
  transform: ${(props) =>
    props.isVisible ? "translateX(0%)" : "translateX(100%)"};
  padding: 10px 15px;
  width: 100%;
  max-width: calc(100% - 20px);
  height: 100%;
  min-height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  transition: transform 300ms cubic-bezier(0.2, 0, 0, 1) 0s;
  z-index: 6;
  overflow-y: auto;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
`;

interface _BackdropProps {
  isVisible?: boolean;
}

export const _Backdrop = styled.div<_BackdropProps>`
  display: ${(props) => (props.isVisible ? "inherit" : "none")};
  opacity: ${(props) => (props.isVisible ? "1" : "0")};
  transition: opacity 0.2s ease-in-out;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;

  @media only screen and (min-width: 1200px) {
    display: none;
    opacity: 0;
    pointer-events: none;
  }
`;
