import React, { FunctionComponent } from "react";
import {
  _CookieConsent,
  _CookieConsentButtons,
  _CookieConsentContent,
} from "./_CookieConsent";
import {
  COOKIE_CONSENT_REJECTED_VALUE,
  COOKIE_CONSENT_ACCEPTED_VALUE,
} from "../../../config/cookies";
import Button, { ButtonVariant } from "../../../components/atoms/Button";
import { useIntl } from "react-intl";
import { useCookiesConsent } from "../../../hooks/useCookiesConsent";
import Link from "../../../components/atoms/Link";

const CookieConsent: FunctionComponent = () => {
  const intl = useIntl();

  const { setConsent } = useCookiesConsent();

  return (
    <_CookieConsent data-testid="cookie-consent">
      <_CookieConsentContent>
        <h2>{intl.formatMessage({ id: "cookieConsent.title" })}</h2>
        <p>{intl.formatMessage({ id: "cookieConsent.intro" })} </p>
        <Link data-testid="cookie-consent-details" href="/cookies">
          {intl.formatMessage({ id: "cookieConsent.details" })}
        </Link>
      </_CookieConsentContent>
      <_CookieConsentButtons>
        <Button
          data-testid="cookie-consent-accept"
          variant={ButtonVariant.DEFAULT}
          onClick={() => {
            setConsent(COOKIE_CONSENT_ACCEPTED_VALUE);
          }}
        >
          {intl.formatMessage({ id: "cookieConsent.accept" })}
        </Button>
        <Button
          data-testid="cookie-consent-reject"
          variant={ButtonVariant.WHITE}
          onClick={() => {
            setConsent(COOKIE_CONSENT_REJECTED_VALUE);
          }}
        >
          {intl.formatMessage({ id: "cookieConsent.deny" })}
        </Button>
      </_CookieConsentButtons>
    </_CookieConsent>
  );
};

export default CookieConsent;
