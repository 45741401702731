import styled, { css } from "styled-components";
import { LinkOrButton } from "../../atoms/LinkOrButton/LinkOrButton";
import { Icon } from "../Icon/Icon";
import { MenuItemVariant } from "./MenuItemVariant";

export const _MenuItem = styled(LinkOrButton)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.55);
  border: 1px solid rgba(219, 222, 229, 0.7);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  margin-bottom: 15px;
  transition: all 150ms ease-in-out;

  &:hover,
  &:focus {
    background-color: rgba(255, 255, 255, 1);
  }
`;

export const _Title = styled.span<{
  variant?: MenuItemVariant;
  active?: boolean;
}>`
  transition: color 0.2s ease-in-out;
  font-size: 16px;
  line-height: 14px;
  font-weight: 700;
  white-space: nowrap;
  margin-left: 10px;
  color: ${(props) => {
    switch (props.variant) {
      case MenuItemVariant.RED:
        return (props) => props.theme.colors.ERROR;
      default:
        return (props) => props.theme.colors.TEXT;
    }
  }};

  ${(props) =>
    props.active &&
    css`
      opacity: 0.4;
    `}
`;

export const _Icon = styled(Icon)<{
  variant?: MenuItemVariant;
  active?: boolean;
}>`
  color: ${(props) => {
    switch (props.variant) {
      case MenuItemVariant.RED:
        return (props) => props.theme.colors.ERROR;
      default:
        return (props) => props.theme.colors.PRIMARY;
    }
  }};

  &:last-child {
    margin-left: auto;
  }

  ${(props) =>
    props.active &&
    css`
      opacity: 0.4;
    `}
`;
