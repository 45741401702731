import styled, { css } from "styled-components";

function getStartPositionForScrollGradientTop() {
  // Make sure the gradient starts at 50% from the top of the topBar
  return css`calc(env(safe-area-inset-top) + ${(props) =>
    props.theme.layout.scrollArea.offsetTop / 2}px)`;
}

function getPaddingTopForScrollContainer() {
  // Scroll starts immediately after the top bar (no overlapping)
  return css`calc(env(safe-area-inset-top) + ${(props) =>
    props.theme.layout.scrollArea.offsetTop}px)`;
}

function getStartPositionForScrollGradientBottom() {
  // Make sure the gradient starts 50% of the height of the dock before the dock itself
  return css`calc(100vh - calc(env(safe-area-inset-bottom) + ${(props) =>
    props.theme.layout.scrollArea.offsetBottom * 1.5}px))`;
}

function getEndPositionForScrollGradientBottom() {
  // Gradient should stop where the dock begins
  return css`calc(100vh - calc(env(safe-area-inset-bottom) + ${(props) =>
    props.theme.layout.scrollArea.offsetBottom}px))`;
}

function getPaddingBottomForScrollContainer() {
  // Scroll area stops before the dock begins (no overlapping)
  return css`calc(${(props) => props.theme.layout.scrollArea.offsetBottom}px)`;
}

export const _AuthHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;

export const _AuthFooter = styled.div`
  padding: 15px;
  color: ${(props) => props.theme.colors.BORDER};
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  min-height: 56px;

  a {
    color: ${(props) => props.theme.colors.PRIMARY};
    font-weight: 600;
  }
`;

export const _Outer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  flex-direction: column;
  height: 100%;
  /* Force to span the entire screen height (MWP-363) */
  @media all and (display-mode: standalone) {
    height: calc(100vh - env(safe-area-inset-bottom));
  }
`;

export const _Top = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
`;

export const _Bottom = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
`;

export const _Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-mask-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0,
    rgba(0, 0, 0, 0) ${getStartPositionForScrollGradientTop()},
    rgba(0, 0, 0, 1) ${getPaddingTopForScrollContainer()},
    rgba(0, 0, 0, 1) ${getStartPositionForScrollGradientBottom()},
    rgba(0, 0, 0, 0) ${getEndPositionForScrollGradientBottom()}
  );
`;

export const _Scroll = styled.div`
  overflow-x: hidden;
  padding-top: ${() => getPaddingTopForScrollContainer()};
  padding-bottom: ${() => getPaddingBottomForScrollContainer()};
  height: 100%;
`;
