import { useMemo } from "react";
import {
  COOKIE_CONSENT_ACCEPTED_VALUE,
  COOKIE_CONSENT_KEY,
  COOKIE_CONSENT_REJECTED_VALUE,
} from "../config/cookies";
import { useCookie } from "./useCookie";
export const useCookiesConsent = () => {
  const { value, update } = useCookie(COOKIE_CONSENT_KEY);

  const isAccepted = useMemo(() => {
    return value === COOKIE_CONSENT_ACCEPTED_VALUE;
  }, [value]);

  const isRejected = useMemo(() => {
    return value === COOKIE_CONSENT_REJECTED_VALUE;
  }, [value]);

  const isNotSet = useMemo(() => {
    return value === undefined || value === null;
  }, [value]);

  const setConsent = (value: string) => {
    update(value);
  };

  return { isNotSet, isAccepted, isRejected, setConsent };
};
