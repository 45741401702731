import styled from "styled-components";
import { CloseButtonSize, CloseButtonVariant } from "./CloneButton.types";

export const _CloseButton = styled.button<{
  size?: CloseButtonSize;
  variant?: CloseButtonVariant;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => {
    switch (props.size) {
      case CloseButtonSize.SMALL:
        return "30px";
      default:
        return "40px";
    }
  }};
  height: ${(props) => {
    switch (props.size) {
      case CloseButtonSize.SMALL:
        return "30px";
      default:
        return "40px";
    }
  }};
  background-color: ${(props) => {
    switch (props.variant) {
      case CloseButtonVariant.RED:
        return "rgba(222, 85, 80, 1)";
      default:
        return props.theme.colors.MENU;
    }
  }};
  border-radius: 50%;
  box-shadow: 0 3px 8px 2px rgba(0, 0, 0, 0.12);
  border: none;
  cursor: pointer;
  z-index: 99;

  span {
    display: block;
    width: 21px;
    height: 3px;
    background-color: white;
    border-radius: 2px;

    &:first-child {
      margin-bottom: 4px;
    }

    &:last-child {
      margin-top: 4px;
    }
  }
`;
