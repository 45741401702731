import { connect } from "react-redux";
import { RootState } from "../../../redux";
import { DockWithStateProps } from "./DockWithStateProps.types";
import Dock from "./Dock";

function mapStateToProps(
  state: RootState
): Pick<DockWithStateProps, "isForgotten"> {
  return {
    isForgotten: state.userMeta.user?.isForgotten,
  };
}

export default connect(mapStateToProps)(Dock);
