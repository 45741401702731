import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import Link from "../atoms/Link";
import MenuTrigger from "../atoms/MenuTrigger";
import Container from "../atoms/Container";
import Menu from "./Menu/Menu";
import { useLabel } from "../../hooks/useLabel";
import { useRouter } from "next/router";
import isToday from "date-fns/isToday";
import { Role } from "../../contexts/Auth/Auth";
import { _IconButton, _Link, _Text } from "./Dock/_Dock";
import { Icon } from "../molecules/Icon/Icon";
import { IconSize } from "../molecules/Icon/IconSize";

interface Props {
  title: any;
  isHomepage?: boolean;
}

const TopBar: FunctionComponent<Props> = ({ title, isHomepage }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { label } = useLabel();

  return (
    <>
      <_TopBar>
        <Container>
          <_TopBarInner>
            <_LogoSlot>
              <Link href="/">
                {isHomepage ? (
                  <>
                    <_LogoFull
                      src={label.assets.logoFull.src}
                      width={label.assets.logoFull.width}
                      height={label.assets.logoFull.height}
                    />
                  </>
                ) : (
                  <_Logo src={label.assets.logo.src} />
                )}
              </Link>
            </_LogoSlot>

            {!isHomepage && <_TopBarTitle>{title}</_TopBarTitle>}

            <_ActionsSlot>
              <MenuTrigger
                onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
              />
            </_ActionsSlot>
          </_TopBarInner>
          <Canvas>
            <li>
              <Link href="/">
                <FormattedMessage defaultMessage="Thuis" />
              </Link>
            </li>
            <li>
              <Link href="/profile">
                <FormattedMessage defaultMessage="Profiel" />
              </Link>
            </li>
            <li>
              <Link href="/time-registration">
                <FormattedMessage defaultMessage="Urenregistratie" />
              </Link>
            </li>
            <li>
              <Link href="/deze-pagina-bestaat-niet">
                <FormattedMessage id="errors.pageDoesNotExist" />
              </Link>
            </li>
            <li>
              <Link href="/time-registration-overview">
                <FormattedMessage defaultMessage="Urenregistratie overzicht" />
              </Link>
            </li>
          </Canvas>
        </Container>
      </_TopBar>
      <Menu isVisible={menuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
};
export default TopBar;

const _TopBar = styled.div``;

const _TopBarInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
`;

const Canvas = styled.div`
  display: none;
`;

const _TopBarTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  color: #2a2a2a;
  margin: 0;
  width: calc(100% / 3);
  text-align: center;
`;

const _ActionsSlot = styled.div`
  display: flex;
  justify-content: flex-end;
  width: calc(100% / 3);
  align-items: center;
`;

const _LogoSlot = styled.div`
  display: grid;
  min-width: calc(100% / 3);
`;

const _Logo = styled.img`
  max-height: 32px;
  object-fit: contain;
`;

const _LogoFull = styled.img`
  width: 100%;
  height: 100%;
  max-height: 32px;
  max-width: 170px;
  object-fit: contain;
  object-position: top left;
`;
