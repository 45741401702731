import React, { FunctionComponent } from "react";
import MenuItem, { MenuItemVariant } from "../../molecules/MenuItem";
import { useRouter } from "next/dist/client/router";
import OffCanvas from "../../molecules/OffCanvas/OffCanvas";
import { _MenuItems, _MenuItemsBottom } from "./_Menu";
import { Role, useAuth } from "../../../contexts/Auth/Auth";
import { impersonate } from "../../../services/impersonate";
import { useIntl } from "react-intl";
import { useHeadsup } from "../../atoms/Headsup/Headsup.provider";
import { Language, useLanguage } from "../../../hooks/useLanguage";
import LanguageDropdown from "../LanguageDropdown/LanguageDropdown";

export interface MenuProps {
  isVisible?: boolean;
  onClose?: () => void;
  dossierNumber?: any;
}

const Menu: FunctionComponent<MenuProps> = ({ isVisible, onClose }) => {
  const router = useRouter();
  const auth = useAuth();
  const intl = useIntl();
  const headsup = useHeadsup();

  const handleLogout = () => {
    auth.signOut();
  };

  const goToHelpdesk = () => {
    impersonate({
      onError: () => {
        headsup.add(intl.formatMessage({ id: "impersonation.error.revert" }));
      },
    });
  };

  const { language, setLanguage } = useLanguage();

  return (
    <OffCanvas isVisible={isVisible} onClose={onClose}>
      {(auth.role === Role.EMPLOYEE ||
        auth.role === Role.IMPERSONATING_EMPLOYEE) && (
        <_MenuItems>
          <MenuItem
            href="/payments"
            title={intl.formatMessage({ id: "menu.payments" })}
            iconLeft="Money"
            iconRight="ArrowRight"
            active={router?.pathname === "/payments"}
          />
          <MenuItem
            href="/worked-hours"
            title={intl.formatMessage({ id: "menu.hours" })}
            iconLeft="SuitCase"
            iconRight="ArrowRight"
            active={router?.pathname === "/worked-hours"}
          />
          <MenuItem
            href="/vacation-hours"
            title={intl.formatMessage({ id: "menu.vacationHours" })}
            iconLeft="SuitcaseHoliday"
            iconRight="ArrowRight"
            active={router?.pathname === "/vacation-hours"}
          />
          <MenuItem
            href="/profile"
            title={intl.formatMessage({ id: "menu.profile" })}
            iconLeft="Person"
            iconRight="ArrowRight"
            active={router?.pathname === "/profile"}
          />
          <MenuItem
            href="/payslips"
            title={intl.formatMessage({ id: "menu.payslips" })}
            iconLeft="Document"
            iconRight="ArrowRight"
            active={router?.pathname === "/payslips"}
          />
          <MenuItem
            href="/help"
            title={intl.formatMessage({ id: "menu.help" })}
            iconLeft="Question"
            iconRight="ArrowRight"
            active={router?.pathname === "/help"}
          />
        </_MenuItems>
      )}
      <LanguageDropdown />
      <_MenuItemsBottom>
        {auth.role === Role.IMPERSONATING_EMPLOYEE && (
          <MenuItem
            onClick={goToHelpdesk}
            title={intl.formatMessage({
              id: "menu.backToHelpdesk",
            })}
            iconLeft="Leave"
            variant={MenuItemVariant.RED}
          />
        )}
        <MenuItem
          onClick={handleLogout}
          title={intl.formatMessage({ id: "menu.logout" })}
          iconLeft="Logout"
          variant={MenuItemVariant.RED}
        />
      </_MenuItemsBottom>
    </OffCanvas>
  );
};
export default Menu;
