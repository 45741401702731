import React, { FunctionComponent } from "react";
import { Icon } from "../../molecules/Icon/Icon";
import { CloseButtonProps, CloseButtonSize } from "./CloneButton.types";
import { _CloseButton } from "./_CloseButton";

const CloseButton: FunctionComponent<CloseButtonProps> = ({
  onClick,
  size,
  variant,
  iconSize,
  ...props
}) => (
  <_CloseButton
    size={size}
    variant={variant}
    data-test-id="canvas-close-button"
    onClick={onClick}
    {...props}
  >
    <Icon name="Cross" fill="#fff" size={iconSize} />
  </_CloseButton>
);

export default CloseButton;

CloseButton.defaultProps = {
  size: CloseButtonSize.DEFAULT,
};
