import React, { FC } from "react";
import {
  _Button,
  _Dock,
  _DockInner,
  _IconButton,
  _IconButtonDanger,
  _Link,
  _Text,
} from "./_Dock";
import { useHeadsup } from "../../atoms/Headsup/Headsup.provider";
import Container from "../../atoms/Container";
import { Icon } from "../../molecules/Icon/Icon";
import { Role, useAuth } from "../../../contexts/Auth/Auth";
import { impersonate } from "../../../services/impersonate";
import { IconSize } from "../../molecules/Icon/IconSize";
import { FormattedMessage, useIntl } from "react-intl";
import { DockWithStateProps } from "./DockWithStateProps.types";
import { useGTM } from "../../../hooks/useGTM";
import { GTMEvents } from "../../../config/gtm-events";
import { useRouter } from "next/router";

const Dock: FC<DockWithStateProps> = ({ isForgotten }) => {
  const auth = useAuth();
  const headsup = useHeadsup();
  const intl = useIntl();
  const { trackEvent } = useGTM();
  const router = useRouter();

  const currentPath = router?.pathname.split("/")[1];

  return (
    <_Dock>
      <Container>
        <_DockInner>
          <_Link
            href="/"
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dock,
                item: "dashboard",
              });
            }}
          >
            <_IconButton active={currentPath === ""}>
              <Icon size={IconSize.MD} name="House" />
              <_Text>
                <FormattedMessage id="dock.dashboard" />
              </_Text>
            </_IconButton>
          </_Link>
          <_Link
            href="/worked-hours"
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dock,
                item: "worked-hours",
              });
            }}
          >
            <_IconButton active={currentPath === "worked-hours"}>
              <Icon size={IconSize.MD} name="Clock" />
              <_Text>
                <FormattedMessage id="dock.hours" />
              </_Text>
            </_IconButton>
          </_Link>
          <_Link
            href="/profile"
            onClick={() => {
              trackEvent({
                event: GTMEvents.Dock,
                item: "profile",
              });
            }}
          >
            <_IconButton active={currentPath === "profile"}>
              <Icon size={IconSize.MD} name="Person" />
              <_Text>
                <FormattedMessage id="dock.profile" />
              </_Text>
            </_IconButton>
          </_Link>
          {/* MWP-393: Hide contact button for forgotten employee */}
          {!(auth.role === Role.EMPLOYEE && isForgotten) && (
            <_Link
              href="/help"
              onClick={() => {
                trackEvent({
                  event: GTMEvents.Dock,
                  item: "help",
                });
              }}
            >
              <_IconButton active={currentPath === "help"}>
                <Icon size={IconSize.MD} name="Question" />
                <_Text>
                  <FormattedMessage id="dock.help" />
                </_Text>
              </_IconButton>
            </_Link>
          )}
          {auth.role === Role.IMPERSONATING_EMPLOYEE && (
            <_Button
              onClick={() =>
                impersonate({
                  onError: () => {
                    headsup.add(
                      intl.formatMessage({ id: "impersonation.error.revert" })
                    );
                  },
                })
              }
              data-testid="stop-impersonating"
            >
              <_IconButtonDanger active={currentPath === "helpdesk"}>
                <Icon size={IconSize.MD} name="Leave" />
                <_Text>
                  <FormattedMessage id="dock.helpdesk" />
                </_Text>
              </_IconButtonDanger>
            </_Button>
          )}
        </_DockInner>
      </Container>
    </_Dock>
  );
};

export default Dock;
