import React, { FunctionComponent } from "react";
import { _Backdrop, _Canvas } from "./_Canvas";

export interface Props {
  isVisible?: boolean;
  onClose?: () => void;
}

const Canvas: FunctionComponent<Props> = ({ children, isVisible, onClose }) => (
  <>
    <_Canvas isVisible={isVisible} data-test-id="off-canvas">
      {children}
    </_Canvas>
    <_Backdrop isVisible={isVisible} onClick={onClose} />
  </>
);

export default Canvas;
