import { useCookies } from "react-cookie";
export const useCookie = (key: string) => {
  const [cookies, setCookie, deleteCookie] = useCookies([key]);

  const value = cookies[key];

  const update = (value: string) => {
    setCookie(key, value);
  };

  const remove = () => {
    deleteCookie(key);
  };

  return { value, update, remove };
};
