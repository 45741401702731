import { GTMEvents } from "../config/gtm-events";
import { useCookiesConsent } from "./useCookiesConsent";

type GTMEventType = Record<string, string> & {
  event: GTMEvents;
};

export const useGTM = () => {
  const { isAccepted } = useCookiesConsent();
  const dataLayer = typeof window !== "undefined" ? window.dataLayer || [] : [];

  const trackEvent = (event: GTMEventType) => {
    if (isAccepted) {
      dataLayer.push(event);
    }
  };

  return {
    trackEvent,
  };
};
