import styled, { css } from "styled-components";
import { BackdropVariant } from "./Backdrop.types";

function bgLayer1(variant: BackdropVariant) {
  switch (variant) {
    case BackdropVariant.AUTH:
    case BackdropVariant.DEFAULT:
    default:
      return css`linear-gradient(
        to bottom right,
        rgba(255, 255, 255, 0.45),
        rgba(255, 255, 255, 0.6)
      )`;
  }
}

function bgLayer2(variant: BackdropVariant) {
  switch (variant) {
    case BackdropVariant.AUTH:
      return "white";
    case BackdropVariant.DEFAULT:
    default:
      return css`linear-gradient(to bottom, ${(props) =>
        props.theme.backdrop.gradient.start} 35%, ${(props) =>
        props.theme.backdrop.gradient.end})`;
  }
}

export const _Backdrop = styled.div<{ variant: BackdropVariant }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${({ variant }) => bgLayer1(variant)},
    ${({ variant }) => bgLayer2(variant)};
  overflow: hidden;
`;

export const _ArtworkLeft = styled.img`
  display: block;
  position: absolute;
  top: 50px;
  left: 0;
`;

export const _ArtworkRight = styled.img`
  display: block;
  position: absolute;
  bottom: 50px;
  right: 0;
`;

export const _BgShapesTop = styled.div`
  position: absolute;
  top: 70px;
  left: 15px;
  background-size: cover;
  width: 100%;
  height: 160px;

  @media only screen and (min-width: 768px) {
    background-size: contain;
    background-repeat: repeat;
  }
`;
