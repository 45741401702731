import React, {
  ReactElement,
  RefObject,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from "react";
import styled from "styled-components";
import { RippleContext } from "../../../contexts/Ripple/Ripple";
import { noSelect } from "../../../styled/noSelectStyles";
import { RippleTypeWithSizes } from "./RippleTypeWithSizes";
import RippleCircle from "./RippleCircle";

type RippleTransitionProps = {
  parentRef?: RefObject<HTMLElement>;
  color?: string;
  centered?: boolean;
  radius?: number | null;
  speed?: string;
};

export function RippleTransition({
  color = "#9b9b9b64",
  centered = false,
  radius = null,
  speed = "360ms",
}: RippleTransitionProps): ReactElement | null {
  const { ripples } = useContext(RippleContext);
  const container = useRef<any>();

  const calculateSizes = useCallback(
    (ripple) => {
      if (!container.current) {
        return null;
      }

      const rect = (
        container.current as HTMLDivElement
      ).getBoundingClientRect();
      const x = ripple.clickX - rect.left;
      const y = ripple.clickY - rect.top;

      const offsetX = Math.abs(x - rect.width / 2);
      const offsetY = Math.abs(y - rect.height / 2);
      const outOfArea = x > rect.width || y > rect.height;

      return {
        outOfArea,
        size: Math.sqrt(
          (rect.width + offsetX * 2) ** 2 + (rect.height + offsetY * 2) ** 2
        ),
        x,
        y,
      };
    },
    [container]
  );

  const rippleWithSizes = useMemo<RippleTypeWithSizes[]>(() => {
    return ripples.map((ripple) => {
      const sizes = calculateSizes(ripple);

      return {
        ...ripple,
        ...sizes,
      } as RippleTypeWithSizes;
    });
  }, [ripples, calculateSizes]);

  return (
    <_RippleTransition ref={container}>
      {rippleWithSizes.map((ripple) => (
        <RippleCircle
          {...ripple}
          key={ripple.id}
          color={color}
          size={radius ? radius * 2 : ripple.size}
          centered={centered}
          speed={speed}
        />
      ))}
    </_RippleTransition>
  );
}

export const _RippleTransition = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${noSelect}
`;

export default RippleTransition;
