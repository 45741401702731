import { ButtonHTMLAttributes } from "react";
import styled, { css } from "styled-components";
import { _RippleTransition } from "../Ripple/Ripple";
import { Svg } from "../Svg/Svg";
import { _SvgContainer } from "../Svg/_SvgContainer";
import { ButtonSize } from "./ButtonSize";
import { ButtonVariant } from "./ButtonVariants";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  rounded?: boolean;
  isLoading?: boolean;
  variant?: ButtonVariant;
  size?: ButtonSize;
  textAlign?: string;
}

export default function Button({
  variant = ButtonVariant.DEFAULT,
  children,
  isLoading,
  textAlign,
  ...props
}: ButtonProps) {
  return (
    <_Button variant={variant} {...props} isLoading={isLoading}>
      <_Content textAlign={textAlign}>{children}</_Content>
      {isLoading && (
        <_Loader>
          <Svg name="Loader" />
        </_Loader>
      )}
    </_Button>
  );
}

const _Content = styled.div<{ isLoading?: boolean; textAlign?: string }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => {
    switch (props.textAlign) {
      case "center":
        return "center";
      case "left":
      default:
        return "space-between";
    }
  }};
  width: 100%;
  opacity: ${(props) => (props.isLoading ? "0" : "1")};
  pointer-events: ${(props) => (props.isLoading ? "none" : "auto")};
`;

const _Button = styled.button<ButtonProps>`
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.variant &&
    props.theme.button.variants[props.variant].backgroundColor};
  transition: all 0.2s ease-in-out;
  text-align: center;
  border: 0;
  border-radius: ${(props) => (props.rounded ? "29px" : "8px")};
  padding: 15px;
  font-weight: 700;
  font-size: 15px;
  cursor: pointer;
  box-shadow: ${(props) => props.theme.shadows[1]};
  font-family: "Isidora Sans Webfont", sans-serif;
  line-height: 1.6rem;
  color: ${(props) =>
    props.variant && props.theme.button.variants[props.variant].textColor};

  &:hover,
  &:focus {
    background: ${(props) =>
      props.variant &&
      props.theme.button.variants[props.variant].backgroundColor};
    outline: none;
    color: ${(props) =>
      props.variant &&
      props.theme.button.variants[props.variant].hover.textColor};
  }

  span {
    position: relative;
    z-index: 2;
  }

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }

  @media only screen and (min-width: 992px) {
    text-align: center;
  }

  ${(props) =>
    props.size === "small" &&
    css`
      padding: 7px 12px;

      // TODO: remove this and use the Icon[size] prop
      svg {
        width: 8px;
        height: 10px;
        margin-left: 6px;
      }
    `}

  ${(props) =>
    props.variant === ButtonVariant.TRANSPARENT &&
    css`
      cursor: pointer;
      box-shadow: none;
      color: ${(props) => props.theme.colors.TEXT_LIGHT};

      &:hover,
      &:focus {
        outline: none;
        color: ${(props) => props.theme.colors.TEXT_LIGHT};
      }
    `}
    
  ${(props) =>
    props.variant === ButtonVariant.LINK &&
    css`
      cursor: pointer;
      box-shadow: none;
      padding: 0;
      &:focus {
        outline: none;
      }
    `}

  // TODO: remove this and use the Icon[fill] prop
  svg {
    fill: currentColor;
  }

  ${_SvgContainer} {
    margin: 0 4px;
  }

  ${_RippleTransition} {
    border-radius: ${(props) => (props.rounded ? "29px" : "8px")};
    z-index: -1;
  }

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        ${_Content} {
          opacity: 0;
        }
      `;
    }
  }}
`;

const _Loader = styled.div<{ isLoading?: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 30px;
    height: 30px;
  }
`;
