import { keysToSnakeCase } from "./casing";

export const toFormData = (obj: any): FormData => {
  const formData = new FormData();

  const objConverted = keysToSnakeCase(obj);

  for (let key in objConverted) {
    switch (objConverted[key]) {
      // formdata does not support boolean values
      case true:
        formData.append(key, "1");
        break;
      case false:
        formData.append(key, "0");
        break;
      default:
        formData.append(key, objConverted[key]);
    }
  }

  return formData;
};
