import fetch from "../fetch";
import { toFormData } from "../convert/formData";

type ImpersonateOptions = {
  externalId?: string | undefined;
  onSuccess?: () => void;
  onError?: () => void;
  onFinally?: () => void;
};

export const impersonate = ({
  externalId = undefined,
  onSuccess = () => {},
  onError = () => {},
  onFinally = () => {},
}: ImpersonateOptions) => {
  fetch("/helpdesk/impersonate", {
    body: toFormData({
      externalId: externalId ? externalId : "",
    }),
    method: "POST",
    retries: 0,
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      return Promise.reject();
    })
    .then(({ success }) => {
      if (success) {
        if (onSuccess) {
          onSuccess();
        }
        window.location.reload();
      }
    })
    .catch(() => {
      if (onError) {
        onError();
      }
    })
    .finally(() => {
      if (onFinally) {
        onFinally();
      }
    });
};
