import React, {
  FunctionComponent,
  PointerEvent,
  useCallback,
  useState,
} from "react";
import RippleContext from "../../../contexts/Ripple/Ripple";
import { RippleStates } from "./RippleStates";
import { RippleType } from "./RippleType";

export { RippleTransition, _RippleTransition } from "./RippleTransition";

export const Ripple: FunctionComponent<any> = ({ children }) => {
  const [ripples, setRipples] = useState<Partial<RippleType>[]>([]);

  // mountain an index for unique keys to the Ripple circles
  const [index, setIndex] = useState(0);

  const addRipple = useCallback(
    ({ clickX, clickY }: { clickX: number; clickY: number }) => {
      const ripple: Partial<RippleType> = {
        id: index,
        clickX,
        clickY,
        state: RippleStates.NEW,
      };

      const newRipples = [...ripples, ripple];

      setRipples(newRipples);
      setIndex(index + 1);
    },
    [ripples, index]
  );

  const hideRipples = useCallback(() => {
    let newRipples = [...ripples];

    newRipples = newRipples.map((ripple) => ({
      ...ripple,
      state: RippleStates.REMOVED,
    }));

    setRipples(newRipples);
  }, [ripples]);

  const removeRippleById = useCallback(
    (id: number) => {
      let newRipples = [...ripples];

      newRipples = newRipples.filter((ripple) => ripple.id !== id);

      setRipples(newRipples);
    },
    [ripples]
  );

  const handleMouseUp = () => {
    hideRipples();
  };

  const handleMouseDown = (e: PointerEvent<HTMLDivElement>) => {
    addRipple({
      clickX: e.clientX,
      clickY: e.clientY,
    });
  };

  return (
    <div
      className="ripple"
      onPointerDown={handleMouseDown}
      onPointerUp={handleMouseUp}
      onPointerLeave={handleMouseUp}
    >
      <RippleContext.Provider value={{ ripples, setRipples, removeRippleById }}>
        {children}
      </RippleContext.Provider>
    </div>
  );
};

export default Ripple;
