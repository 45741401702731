import { IconSize } from "../../molecules/Icon/IconSize";

export enum CloseButtonSize {
  DEFAULT = "DEFAULT",
  SMALL = "SMALL",
}

export enum CloseButtonVariant {
  DEFAULT = "DEFAULT",
  RED = "RED",
}

export interface CloseButtonProps {
  onClick?: () => void;
  size?: CloseButtonSize;
  variant?: CloseButtonVariant;
  iconSize?: IconSize;
}
