import { css } from "styled-components";

export const noSelect = () => css`
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none; /* Safari Touch */
  -webkit-user-select: none; /* Webkit */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Edge*/
  user-select: none; /* Future-proof*/
`;
