import { useEffect, useMemo } from "react";
import Button, { ButtonVariant } from "../atoms/Button";
import { FormattedMessage } from "react-intl";
import { Banner } from "../molecules/Banner";
import { useBanner } from "../../contexts/BannerOrchestrator";
import storageAvailable from "storage-available";

export default function PwaBanner() {
  const { hideBanner, bannerIsVisible } = useShowPwaBanner();

  return (
    <StaticPwaBanner visible={bannerIsVisible} onClose={() => hideBanner()} />
  );
}

export interface StaticPwaBannerProps {
  visible: boolean;
  onClose: () => void;
}

export function StaticPwaBanner({ visible, onClose }: StaticPwaBannerProps) {
  return (
    <Banner visible={visible}>
      <p>
        <FormattedMessage id="pwa" />
      </p>
      <Banner.Actions>
        <Banner.Action>
          <Button variant={ButtonVariant.TRANSPARENT} onClick={onClose}>
            <FormattedMessage defaultMessage="Sluiten" />
          </Button>
        </Banner.Action>
      </Banner.Actions>
    </Banner>
  );
}

function useShowPwaBanner() {
  const { hideBanner, bannerIsVisible, showBanner } = useBanner();

  useEffect(() => {
    const isIos = /iphone|ipad|ipod/.test(
      window.navigator.userAgent.toLowerCase()
    );

    const isStandalone = window.matchMedia(
      "(display-mode: standalone)"
    ).matches;

    // Checks if should display install popup notification:
    if (
      isIos &&
      !isStandalone &&
      storageAvailable("localStorage") &&
      !window.localStorage.getItem("pwa_banner_seen")
    ) {
      showBanner();

      setTimeout(() => {
        hideBanner();
        storageAvailable("localStorage") &&
          window.localStorage.setItem("pwa_banner_seen", Date.now().toString());
      }, 10000);
    }
  }, [hideBanner, showBanner]);

  return useMemo(
    () => ({ hideBanner, bannerIsVisible, showBanner }),
    [bannerIsVisible, hideBanner, showBanner]
  );
}
