import React, { FunctionComponent } from "react";
import { Language, useLanguage } from "../../../hooks/useLanguage";
import {
  _LanguageDropdown,
  _LanguageDropdownContainer,
} from "./_LanguageDropdown";
import { LanguageDropdownProps } from "./LanguageDropdown.types";
import { IconSize } from "../../molecules/Icon/IconSize";
import { Icon } from "../../molecules/Icon/Icon";

const LanguageDropdown: FunctionComponent<LanguageDropdownProps> = ({
  className,
  children,
}) => {
  const { language, setLanguage } = useLanguage();

  return (
    <_LanguageDropdownContainer>
      <Icon name="Globe" size={IconSize.SM} />
      <_LanguageDropdown
        value={language}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
          setLanguage(e.currentTarget.value as Language)
        }
      >
        <option value={Language.NL}>Nederlands</option>
        <option value={Language.EN}>English</option>
      </_LanguageDropdown>
    </_LanguageDropdownContainer>
  );
};

export default LanguageDropdown;
