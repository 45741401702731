import React, {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from "react";
import styled, { css, keyframes } from "styled-components";
import RippleContext from "../../../contexts/Ripple/Ripple";
import { RippleStates } from "./RippleStates";
import { RippleTypeWithSizes } from "./RippleTypeWithSizes";

interface RippleCircleProps extends RippleTypeWithSizes {
  color: string;
  centered: boolean;
  speed: string;
}

export default function RippleCircle({
  id,
  x,
  y,
  size,
  state,
  color,
  speed,
  centered,
  outOfArea,
}: RippleCircleProps): ReactElement | null {
  const { removeRippleById } = useContext(RippleContext);
  const element = useRef<null | HTMLDivElement>(null);

  const removeRipple = useCallback(() => {
    removeRippleById(id);
  }, [id, removeRippleById]);

  useEffect(() => {
    const ref = element.current;

    if (state === RippleStates.REMOVED) {
      if (!element.current) {
        removeRipple();
      } else {
        element.current.addEventListener("transitionend", removeRipple);
      }
    }

    return () => {
      ref && ref.removeEventListener("transitionend", removeRipple);
    };
  }, [state, removeRipple, outOfArea]);

  return !outOfArea ? (
    <_RippleCircle
      x={x}
      y={y}
      size={size}
      state={state}
      ref={element}
      color={color}
      centered={centered}
      speed={speed}
    />
  ) : null;
}

export const _showRipple = keyframes`
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
`;

export const _RippleCircle = styled.div<{
  y: number;
  x: number;
  state: string;
  size: number;
  color: string;
  speed: string;
  centered: boolean;
}>`
  position: absolute;
  ${(props) => {
    if (props.centered) {
      return css`
        top: 50%;
        left: 50%;
      `;
    }

    return css`
      top: ${props.y}px;
      left: ${props.x}px;
    `;
  }};
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  margin-left: ${(props) => -props.size / 2}px;
  margin-top: ${(props) => -props.size / 2}px;
  border-radius: 100%;
  background-color: ${(props) => props.color};
  animation: ${_showRipple};
  animation-duration: ${(props) => props.speed};
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
  opacity: 1;
  transition: opacity 200ms;

  ${(props) =>
    props.state === "removed" &&
    css`
      opacity: 0;
    `}
`;
