export enum GTMEvents {
  ReportBetter = "report:better",
  ReportSick = "report:sick",
  ReportExit = "report:exit",
  DocumentsDownload = "documents:download",
  ModifyPersonalDetails = "personal:modify-personal-details",
  ModifyFinancialDetails = "personal:modify-financial-details",
  ModifyAccountDetails = "personal:modify-account-details",
  ModifyWageTaxDiscount = "personal:modify-wage-tax-discount",
  VacationHoursRequest = "vacation-hours:request",
  VacationHoursEdit = "vacation-hours:edit",
  VacationHoursDelete = "vacation-hours:delete",
  PayslipsDownload = "payslips:download",
  PayslipsView = "payslips:view",
  ContactWhatsapp = "contact:whatsapp",
  ContactEmail = "contact:email",
  Dock = "dock",
  Dashboard = "dashboard",
  QuickLink = "quick-link",
}
