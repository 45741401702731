import React, { FunctionComponent } from "react";
import { LinkOrButtonType } from "../../atoms/LinkOrButton/LinkOrButton";
import { MenuItemVariant } from "./MenuItemVariant";
import { _Icon, _MenuItem, _Title } from "./_MenuItem";
import { IconSize } from "../Icon/IconSize";
import { IconName } from "../Icon/IconName";

export type MenuItemProps = {
  iconLeft: IconName;
  iconRight?: IconName;
  title: string;
  variant?: MenuItemVariant;
  active?: boolean;
} & Partial<LinkOrButtonType>;

const MenuItem: FunctionComponent<MenuItemProps> = ({
  title,
  iconLeft,
  iconRight,
  variant,
  active,
  ...props
}) => (
  <_MenuItem {...props}>
    {iconLeft && (
      <_Icon
        size={IconSize.LG}
        name={iconLeft}
        variant={variant}
        active={active}
      />
    )}
    <_Title variant={variant} active={active}>
      {title}
    </_Title>
    {iconRight && !active && (
      <_Icon size={IconSize.MD} name={iconRight} variant={variant} />
    )}
  </_MenuItem>
);

export default MenuItem;

MenuItem.defaultProps = {
  variant: MenuItemVariant.DEFAULT,
};
