import styled, { css } from "styled-components";
import { ReactNode } from "react";

export interface BannerProps {
  visible: boolean;
  children: ReactNode;
}

export function Banner({ visible, children }: BannerProps) {
  return <Wrapper visible={visible}>{children}</Wrapper>;
}

Banner.Actions = styled.div`
  display: flex;
  width: 100%;
`;

Banner.Action = styled.div`
  padding: 0 5px;
`;

const Wrapper = styled.div<{ visible: boolean }>`
  background: ${(props) => props.theme.banner.backgroundColor};
  padding: 20px;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  transform: translateY(${(props) => (props.visible ? "0" : "100%")});

  transition: transform 100ms ease-in-out;

  ${(props) =>
    props.visible &&
    css`
      box-shadow: 0 5px 10px rgba(0, 0, 0, 1);
    `}
`;
