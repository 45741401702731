import styled from "styled-components";
import { _SvgContainer } from "../../atoms/Svg/_SvgContainer";
import Link from "../../atoms/Link";

export const _Dock = styled.div`
  background-color: white;
  width: 100%;
  box-shadow: ${(props) => props.theme.shadows[0]};
`;

export const _DockInner = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 0 10px 0;
`;

export const _IconButton = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 600;
  font-size: 10px;
  color: ${(props) => (props.active ? props.theme.colors.PRIMARY : "#afafaf")};
  transition: color 100ms ease-in-out;

  ${_SvgContainer} .injected-svg {
    fill: currentColor;
    transition: color 100ms ease-in-out;
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.DOCK};

    ${_SvgContainer} .injected-svg {
      fill: ${(props) => props.theme.colors.DOCK};
    }
  }
`;

export const _IconButtonDanger = styled(_IconButton)`
  color: ${(props) => props.theme.colors.ERROR};

  ${_SvgContainer} .injected-svg {
    fill: ${(props) => props.theme.colors.ERROR};
  }

  &:hover,
  &:focus {
    color: ${(props) => props.theme.colors.ERROR};

    ${_SvgContainer} .injected-svg {
      fill: ${(props) => props.theme.colors.ERROR};
    }
  }
`;

export const _Text = styled.span`
  display: block;
`;

export const _Link = styled(Link)`
  background-color: transparent;
  border: none;
  padding: 0;
  text-decoration: none;
`;

export const _Button = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
`;
