import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface Props {
  onClick?: () => void;
}

const MenuTrigger: FunctionComponent<Props> = ({ onClick }) => (
  <_MenuTrigger onClick={onClick} data-test-id="menu-trigger">
    <span />
    <span />
    <span />
  </_MenuTrigger>
);

export default MenuTrigger;

const _MenuTrigger = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.15);
  border: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  box-shadow: 0 2px 5px 4px rgba(0, 0, 0, 0.04);
  cursor: pointer;
  margin-left: 15px;

  span {
    display: block;
    width: 21px;
    height: 2px;
    background-color: ${(props) => props.theme.colors.MENU};
    border-radius: 2px;

    &:first-child {
      margin-bottom: 4px;
    }

    &:last-child {
      margin-top: 4px;
    }
  }
`;
