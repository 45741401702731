import NextLink, { LinkProps as NextLinkProps } from "next/link";
import styled from "styled-components";
import { AnchorHTMLAttributes, ReactNode } from "react";

const Anchor = styled.a`
  margin: 0;
  color: ${(props) => props.theme.colors.TEXT_LIGHT};
  font-weight: 600;
  text-decoration: none;
`;

interface LinkProps
  extends Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">,
    Pick<NextLinkProps, "href"> {
  children: ReactNode;
}

/**
 * Component that works like your regular `<a>` tag but makes navigation smoother.
 */
export default function Link({ href, children, ...props }: LinkProps) {
  return (
    <NextLink href={href} passHref>
      <Anchor {...props}>{children}</Anchor>
    </NextLink>
  );
}
