import React, { FunctionComponent, ReactNode } from "react";
import styled, { css } from "styled-components";

interface Props {
  fullHeight?: boolean;
  children?: ReactNode;
}

const Container: FunctionComponent<Props> = ({ fullHeight, children }) => (
  <_Container fullHeight={fullHeight}>{children}</_Container>
);

const _Container = styled.div<{ fullHeight?: boolean }>`
  width: 100%;
  max-width: 576px;
  padding: 0 15px;
  margin: 0 auto;
  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `}
`;

export default Container;
