import React, { FunctionComponent } from "react";
import Canvas from "../../atoms/Canvas/Canvas";
import CloseButton from "../../atoms/CloseButton/CloseButton";
import { useLabel } from "../../../hooks/useLabel";
import { _CanvasContent, _CanvasHeader, _Link, _Logo } from "./_OffCanvas";

export interface Props {
  isVisible?: boolean;
  onClose?: () => void;
  children?: any;
}

const OffCanvas: FunctionComponent<Props> = ({
  onClose,
  isVisible,
  children,
}) => {
  const { label } = useLabel();

  return (
    <Canvas onClose={onClose} isVisible={isVisible}>
      <_CanvasHeader>
        <_Link href="/">
          <_Logo
            src={label.assets.logoFull.src}
            width={label.assets.logo.width}
            height={label.assets.logo.height}
          />
        </_Link>
        <CloseButton onClick={onClose} />
      </_CanvasHeader>
      <_CanvasContent>{children}</_CanvasContent>
    </Canvas>
  );
};

export default OffCanvas;
