import styled from "styled-components";
import Link from "../../atoms/Link";
import LanguageSelect from "../../organisms/LanguageSelect/LanguageSelect";

export const _CanvasHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: env(safe-area-inset-top);
`;

export const _CanvasContent = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100% - 76px);
`;

export const _Link = styled(Link)`
  height: 22px;
`;

export const _Logo = styled.img`
  width: auto;
  max-height: 100%;
`;
