import styled from "styled-components";

export const _CookieConsent = styled.div`
  background-color: ${(props) => props.theme.colors.WHITE};
  box-shadow: ${(props) => props.theme.shadows[0]};
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: fixed;
  bottom: 0;
  z-index: 100;
  width: 100%;
  padding: 1.5rem;

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const _CookieConsentContent = styled.div`
  @media only screen and (min-width: 768px) {
    flex: 1 1 0;
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
`;

export const _CookieConsentButtons = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  gap: 15px;
`;
